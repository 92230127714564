@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 700;
  src: local('Spoqa Han Sans Bold'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Bold.woff2')
      format('woff2'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Bold.woff')
      format('woff'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Bold.ttf')
      format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 400;
  src: local('Spoqa Han Sans Regular'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Regular.woff2')
      format('woff2'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Regular.woff')
      format('woff'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Regular.ttf')
      format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 300;
  src: local('Spoqa Han Sans Light'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Light.woff2')
      format('woff2'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Light.woff')
      format('woff'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Light.ttf')
      format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 100;
  src: local('Spoqa Han Sans Thin'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Thin.woff2')
      format('woff2'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Thin.woff')
      format('woff'),
    url('https://res.cloudinary.com/nalubaale/raw/upload/v1567312489/fonts/Spoqa_Han_Sans_Thin.ttf')
      format('truetype');
  font-display: swap;
}
